import React from "react";
import Footer from "../Footer";

function LEDgoggles1() {

  return (
    <div className="page">
      <h1>LED Goggles 1</h1>
        <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem turpis, bibendum ut enim a, euismod eleifend arcu. Quisque pulvinar, ligula eget rutrum tincidunt, enim libero vestibulum magna, et eleifend turpis neque sit amet nisi. Morbi nec ligula tincidunt nulla porttitor malesuada ac vel nulla. Vivamus dignissim iaculis diam, a dictum lacus facilisis in. Nulla dui metus, auctor vitae odio aliquet, accumsan mattis nibh. Phasellus aliquet lectus a eleifend convallis. Etiam justo tellus, ornare at leo semper, dapibus dignissim purus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur a mollis risus. Sed vulputate ullamcorper lobortis. Mauris cursus nisl eget dapibus luctus. Vestibulum at justo id mauris aliquam suscipit vel eget eros. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
        Duis porta rutrum eros vitae aliquet. Mauris efficitur vitae mi vitae lobortis. Sed vitae aliquam sem, placerat scelerisque sapien. Duis pulvinar, magna at imperdiet ultrices, ex orci volutpat sapien, sed volutpat lorem est eget velit. Aliquam vel commodo enim, in lacinia ipsum. Fusce et tempor diam. Aliquam ex neque, aliquam ac est venenatis, lacinia lobortis neque. Pellentesque non condimentum lorem. Donec urna ipsum, consequat eget elementum ut, imperdiet quis sapien. Donec quis risus feugiat, porttitor erat et, placerat ex. Morbi accumsan risus nisi, id porttitor enim maximus aliquet. Phasellus elementum quam eget sem ultrices rhoncus. Aenean scelerisque nisl et scelerisque tincidunt. Donec vitae justo interdum, bibendum sem vitae, fringilla urna.
        Nullam eleifend velit purus, vel faucibus enim dictum ac. Nunc aliquam laoreet justo, pellentesque lacinia mi commodo quis. Etiam placerat placerat ante at faucibus. Quisque eu risus orci. Duis eget est vestibulum, lacinia dolor id, tincidunt neque. Vivamus molestie at nisl ac scelerisque. Aenean pharetra luctus pretium. Sed sollicitudin ullamcorper mi, eu dapibus nibh pretium nec. Nulla vitae vehicula orci. Etiam ex massa, rutrum in ante vitae, facilisis tincidunt lorem. Donec volutpat vehicula vulputate. Pellentesque fringilla maximus felis et scelerisque. In eget ornare nisi, id maximus sem. Etiam mi neque, blandit non fermentum in, elementum et mauris.
        Maecenas congue condimentum leo. Phasellus vitae congue augue, vitae ullamcorper quam. Sed vel orci accumsan, pellentesque nisi non, facilisis libero. Donec blandit faucibus porta. Nunc lacinia dolor purus, at venenatis arcu sollicitudin ut. Suspendisse euismod pretium nisi. Proin sed aliquet nunc, sed tincidunt sem.
        Praesent mollis ligula ac tellus sagittis aliquet. Proin at lacus ac eros placerat placerat. Aliquam erat volutpat. Aliquam quam leo, varius vitae efficitur in, vulputate eget ligula. Aliquam elit justo, dignissim et sollicitudin egestas, consequat a ipsum. Nulla congue venenatis enim, ut vulputate ante dapibus vel. Donec pretium, purus eget eleifend hendrerit, ipsum elit luctus leo, nec euismod eros sem ut ipsum. Nullam eu dolor id justo dapibus feugiat. Proin vel justo elementum nisl convallis iaculis. Duis luctus dolor felis, at sagittis tellus ultrices vitae. Phasellus rhoncus velit pulvinar porttitor consectetur. Fusce mollis malesuada elit, quis sagittis nulla.
        </p>
        <Footer />
    </div>
  );
}

export default LEDgoggles1;
